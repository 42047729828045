// data/resume.js
export const resumeContent = {
  education: {
    title: 'Education',
    icon: '🎓',
    items: [
      {
        title: 'Bachelor of Science in Computer Science',
        subtitle: 'University of Florida | Gainesville, FL',
        details: ['GPA: 3.77', 'Graduation: May 2024']
      }
    ]
  },
  skills: {
    title: 'Skills',
    icon: '⚡',
    items: [
      {
        title: 'Programming Languages & Libraries',
        details: [
          'Python (ArcPy, geopandas, Flask)',
          'JavaScript (Leaflet.js, React)',
          'Java',
          'C++',
          'HTML/CSS',
          'SQL'
        ]
      },
      {
        title: 'Software & Tools',
        details: [
          'ArcGIS Pro',
          'git',
          'Linux',
          'Microsoft Office'
        ]
      },
      {
        title: 'Languages',
        details: [
          'English (Native)',
          'Spanish (Fluent)'
        ]
      }
    ]
  }
};