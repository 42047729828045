export const timelineItems = [
  {
    date: "April 2024 - Present",
    title: "Data Analysis Lead Contributor",
    org: "UF Election Lab",
    details: [
      "Enabled critical voter data collection across all 67 Florida county websites by developing an LLM-powered pipeline that adapts to varying voter data formats, collecting 6,765 records",
      "Handled inconsistent website layouts, varying file types, nested and paginated pages, and authentication challenges",
      "Developed a human-in-the-loop command-line tool that displayed collected data to ensure accuracy of extracted voter records",
    ],
  },
  {
    date: "August 2023 - May 2024",
    title: "Team EchoPilot Member",
    org: "Freedom Scientific",
    details: [
      "Led development of JAWS screen reader chatbot testing infrastructure through structured collaboration with a Freedom Scientific liaison, a UF faculty coach, and a five-person student team in weekly meetings",
      "Drove chatbot improvements through testing iterative changes and optimizing hyperparameters",
    ],
  },
  {
    date: "January 2023 - May 2024",
    title: "Software Engineer Intern",
    org: "Florida Community Innovation Foundation",
    details: [
      "Improved accessibility to vital community resources by refactoring the Florida Resource Map project's front page, search, sign in, and resources React components to match Figma designs, supporting a platform used by social workers and nonprofits across Florida",
    ],
  },
  {
    date: "June 2024 - September 2024",
    title: "Recreation Attendant",
    org: "Yosemite Hospitality",
    details: [
      "Supported successful reopening of High Sierra Camps in Yosemite National Park",
      "Provided positive guest experience for 30+ daily visitors",
      "Aided camp operations and maintenance",
    ],
  },
];
