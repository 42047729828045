export const featuredWork = [
  {
    title: "Data Analysis for Election Lab",
    subtitle: "UF Election Lab",
    description:
      "Enabled critical voter data collection across all 67 Florida county websites by developing an LLM-powered pipeline that adapts to varying voter data formats, collecting 6,765 records",
    image: "/images/voter-data.jpg",
    technologies: ["Python", "OpenAI", "Selenium", "BeautifulSoup"],
    sourceUrl:
      "https://drive.google.com/drive/folders/1dpITSj7CYyY-1_tQds8XkgK_ITUdnN8I?usp=sharing",
  },
  {
    title: "JAWS Screen Reader Chatbot",
    subtitle: "Freedom Scientific",
    description:
      "Led development of JAWS screen reader chatbot testing infrastructure through structured collaboration with a Freedom Scientific liaison, a UF faculty coach, and a five-person student team in weekly meetings",
    image: "/images/jaws-chatbot.jpg",
    technologies: ["Python", "pytest"],
    demoUrl:
      "https://support.freedomscientific.com/Downloads/JAWS/JAWSWhatsNew#latest",
  },
  {
    title: "Florida Resource Map",
    subtitle: "Florida Community Innovation Foundation",
    description:
      "Improved accessibility to vital community resources by refactoring the Florida Resource Map project's front page, search, sign in, and resources React components to match Figma designs",
    image: "/images/resource-map.jpg",
    technologies: ["React", "JavaScript", "MUI"],
    demoUrl: "https://www.floridaresourcemap.org/",
  },
  {
    title: "Gainesville RTS Bus Stop Maps",
    description:
      "Successfully automated the creation of 1,600+ bus stop maps for the Gainesville RTS transit system, reducing manual cartography time from weeks to hours while ensuring consistent styling",
    image: "/images/bus-stops.jpg",
    technologies: ["Python", "ArcPy", "GTFS"],
    demoUrl: "https://bus-stop-viewer-1c1ac1eaaf38.herokuapp.com/",
    sourceUrl:
      "https://drive.google.com/drive/folders/1EMnFU-SXSno5vb0PkBfPMrV7xI_jLNUe?usp=drive_link",  
    },
  {
    title: "Yosemite Trail Reporter",
    description:
      "Developed a Flask-based trail conditions reporting system using Python, SQLite, and Leaflet.js, enabling real-time tracking and visualization of user-reported trail hazards",
    image: "/images/trail-reporter.jpg",
    technologies: ["Flask", "Python", "SQLite", "Leaflet.js"],
    demoUrl: "https://yosemite-trail-reporter-d58192061829.herokuapp.com/",
    sourceUrl:
      "https://drive.google.com/drive/folders/1iwqDr2NNdsYJAfQQKMJkm1yn7wNNtLRi?usp=sharing",
  },
];
