import React, { useState, useEffect } from "react";
import { FileCode, ExternalLink, FileText } from "lucide-react";
import { motion } from "framer-motion";
import ParticleBackground from "./ParticleBackground";

import { techStack } from "../data/techStack";
import { featuredWork } from "../data/featuredWork";
import { timelineItems } from "../data/timeline";
import { resumeContent } from "../data/resume";

const HexagonProfile = ({ imageUrl, size = 192 }) => {
  // Calculate points for regular hexagon
  const points = [];
  for (let i = 0; i < 6; i++) {
    const angle = ((i * 60 - 30) * Math.PI) / 180;
    const x = size / 2 + (size / 2) * Math.cos(angle);
    const y = size / 2 + (size / 2) * Math.sin(angle);
    points.push(`${x},${y}`);
  }

  const pointsString = points.join(" ");

  return (
    <div className="relative" style={{ width: size, height: size }}>
      <svg width={size} height={size} className="absolute top-0 left-0">
        <defs>
          <clipPath id="hex-clip">
            <polygon points={pointsString} />
          </clipPath>
        </defs>
        {/* First draw the image */}
        <image
          href={imageUrl}
          width={size}
          height={size}
          clipPath="url(#hex-clip)"
          preserveAspectRatio="xMidYMid slice"
        />
        {/* Then draw the border on top */}
        <polygon
          points={pointsString}
          fill="none"
          stroke="#5eead4"
          strokeWidth="4"
          className="shadow-lg"
        />
      </svg>
    </div>
  );
};

const ProjectCard = ({ project }) => {
  return (
    <div className="bg-gray-900/60 rounded-lg overflow-hidden group backdrop-blur-sm h-full flex flex-col">
      <div className="relative w-full">
        <div className="aspect-video w-full relative overflow-hidden">
          <img
            src={project.image}
            alt={project.title}
            className="w-full h-full object-contain bg-gray-800/50 transition-transform duration-300 group-hover:scale-105"
          />
          {/* Dark overlay that appears on hover */}
          <div
            className="absolute inset-0 bg-gray-900/80 opacity-0 group-hover:opacity-100 
              transition-opacity duration-300 flex items-center justify-center space-x-4"
          >
            {project.demoUrl && (
              <a
                href={project.demoUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="p-2 bg-teal-500 rounded-full hover:bg-teal-400 transition-colors"
                aria-label={`View ${project.title} demo`}
              >
                <ExternalLink size={24} />
              </a>
            )}
            {project.sourceUrl && (
              <a
                href={project.sourceUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="p-2 bg-gray-700 rounded-full hover:bg-gray-600 transition-colors"
                aria-label={`View ${project.title} source code`}
              >
                <FileCode size={24} />
              </a>
            )}
          </div>
        </div>
        <div className="p-6 flex-1 flex flex-col">
          <h3 className="text-xl font-serif text-teal-300 mb-2">
            {project.title}
          </h3>
          {project.subtitle && (
            <p className="text-gray-400 font-serif italic mb-2">
              {project.subtitle}
            </p>
          )}
          <p className="text-gray-300 mb-4 flex-1">{project.description}</p>
          <div className="flex flex-wrap gap-2 mt-auto">
            {project.technologies.map((tech, techIndex) => (
              <span
                key={techIndex}
                className="px-3 py-1 bg-gray-800 rounded-full text-sm text-gray-300"
              >
                {tech}
              </span>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const Portfolio = () => {
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="min-h-screen bg-[#0a192f] text-white relative">
      <ParticleBackground />

      {/* Header with Profile Picture */}
      <motion.header
        className="py-20 px-8 bg-gray-900/40 relative z-10"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
      >
        <div className="max-w-5xl mx-auto flex items-center gap-8">
          <div className="flex-1">
            <h1 className="text-5xl font-serif mb-4">Antonio Migotto</h1>
            <p className="text-teal-300 font-serif text-2xl mb-6">
              GIS Analyst & Software Engineer
            </p>
          </div>
          <div className="hidden md:block">
            <HexagonProfile imageUrl="/images/profile.jpg" />
          </div>
        </div>
      </motion.header>

      <main className="max-w-5xl mx-auto px-8 py-12 space-y-20 relative z-10">
        {/* Education */}
        <motion.section
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
        >
          <h2 className="text-3xl font-serif text-white mb-8 flex items-center">
            <span className="text-4xl mr-4">🎓</span>Education
          </h2>
          <div className="bg-gray-900/60 p-8 rounded-lg backdrop-blur-sm">
            <h3 className="text-xl font-serif text-teal-300">
              Bachelor of Science in Computer Science
            </h3>
            <p className="text-gray-400 font-serif italic mt-2">
              University of Florida | Gainesville, FL
            </p>
            <ul className="list-disc list-inside mt-4 space-y-2 text-gray-300">
              <li>GPA: 3.77</li>
              <li>Graduation: May 2024</li>
            </ul>
          </div>
        </motion.section>

        {/* Featured Work */}
        <motion.section
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
        >
          <h2 className="text-3xl font-serif text-white mb-8 flex items-center">
            <span className="text-4xl mr-4">💼</span>Featured Work
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {featuredWork.map((project, index) => (
              <motion.div
                key={index}
                className="h-full"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.6, delay: index * 0.1 }}
              >
                <ProjectCard project={project} />
              </motion.div>
            ))}
          </div>
        </motion.section>

        {/* Timeline */}
        <motion.section
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
        >
          <h2 className="text-3xl font-serif text-white mb-8 flex items-center">
            <span className="text-4xl mr-4">📅</span>Timeline
          </h2>
          <div className="space-y-12">
            {timelineItems.map((item, index) => (
              <motion.div
                key={index}
                className="bg-gray-900/60 p-6 rounded-lg backdrop-blur-sm mb-8 last:mb-0"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.6, delay: index * 0.1 }}
              >
                <span className="text-teal-300 font-mono text-sm">
                  {item.date}
                </span>
                <h3 className="text-xl font-serif text-white mt-2">
                  {item.title}
                </h3>
                <p className="text-gray-400 italic">{item.org}</p>
                <ul className="mt-4 space-y-2 text-gray-300">
                  {item.details.map((detail, i) => (
                    <li key={i} className="list-disc list-inside">
                      {detail}
                    </li>
                  ))}
                </ul>
              </motion.div>
            ))}
          </div>
        </motion.section>

        {/* Skills & Technologies */}
        <motion.section
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
        >
          <h2 className="text-3xl font-serif text-white mb-8 flex items-center">
            <span className="text-4xl mr-4">⚡</span>Technologies
          </h2>
          <div className="bg-gray-900/60 p-8 rounded-lg backdrop-blur-sm">
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 gap-8">
              {Object.entries(techStack).map(([key, tech]) => (
                <motion.div
                  key={key}
                  className="flex flex-col items-center group"
                  whileHover={{ scale: 1.1 }}
                  transition={{ type: "spring", stiffness: 300 }}
                >
                  <div className="w-16 h-16 flex items-center justify-center">
                    <img
                      src={tech.icon}
                      alt={tech.name}
                      className="w-12 h-12 object-contain"
                    />
                  </div>
                  <span className="mt-2 text-gray-300 text-center">
                    {tech.name}
                  </span>
                </motion.div>
              ))}
            </div>

            <div className="mt-12">
              <h3 className="text-xl font-serif text-teal-300 mb-4">
                Languages
              </h3>
              <div className="flex flex-wrap gap-4">
                {resumeContent.skills.items[2].details.map(
                  (language, index) => (
                    <span
                      key={index}
                      className="px-4 py-2 bg-gray-800 rounded-lg text-gray-300"
                    >
                      {language}
                    </span>
                  )
                )}
              </div>
            </div>
          </div>
        </motion.section>

        {/* Contact Section */}
        <motion.section
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
          className="pb-20"
        >
          <h2 className="text-3xl font-serif text-white mb-8 flex items-center">
            <span className="text-4xl mr-4">📬</span>Get in Touch
          </h2>
          <div className="bg-gray-900/60 p-8 rounded-lg backdrop-blur-sm text-center">
            <p className="text-gray-300 text-lg mb-6">
              I'm currently looking for new opportunities and would love to hear
              from you!
            </p>
            <div className="flex justify-center gap-4">
              <a
                href="mailto:migottoantonio@protonmail.com"
                className="px-6 py-3 bg-teal-500 text-white rounded-lg hover:bg-teal-400 transition-colors"
              >
                Email Me
              </a>
              <a
                href="/migotto_gis_resume.pdf"
                className="px-6 py-3 bg-gray-700 text-white rounded-lg hover:bg-gray-600 transition-colors flex items-center gap-2"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FileText size={20} />
                GIS Analyst Resume
              </a>
              <a
                href="/migotto_resume.pdf"
                className="px-6 py-3 bg-gray-700 text-white rounded-lg hover:bg-gray-600 transition-colors flex items-center gap-2"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FileText size={20} />
                Software Engineer Resume
              </a>
            </div>
          </div>
        </motion.section>
      </main>
    </div>
  );
};

export default Portfolio;
